import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Card, 
  CardMedia, 
  CardContent,
  Dialog,
  IconButton,
  DialogContent,
  useTheme,
  useMediaQuery,
  Skeleton,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface GalleryProps {
  category: string;
}

interface Image {
  id: number;
  url: string;
  title: string;
  description: string;
}

// Sample images for demonstration
const getSampleImages = (category: string): Image[] => {
  return Array.from({ length: 12 }, (_, index) => ({
    id: index,
    url: `https://picsum.photos/1200/800?random=${index}`,
    title: `${category} Photo ${index + 1}`,
    description: `A beautiful ${category.toLowerCase()} photograph`
  }));
};

interface TouchPosition {
  startX: number;
  startY: number;
}

const Gallery = ({ category }: GalleryProps) => {
  const images = getSampleImages(category);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const [loadingStates, setLoadingStates] = useState<{ [key: number]: boolean }>(
    images.reduce((acc, img) => ({ ...acc, [img.id]: true }), {})
  );
  const [lightboxLoading, setLightboxLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [touchStart, setTouchStart] = useState<TouchPosition | null>(null);
  const swipeThreshold = 50; // minimum distance for swipe

  const preventImageDownload = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
  };

  const handleImageLoad = (imageId: number) => {
    setLoadingStates(prev => ({ ...prev, [imageId]: false }));
  };

  const handleImageClick = (image: Image) => {
    setSelectedImage(image);
    setLightboxLoading(true);
  };

  const handleLightboxImageLoad = () => {
    setLightboxLoading(false);
  };

  const handleClose = () => {
    setSelectedImage(null);
    setLightboxLoading(false);
  };

  const handleNext = () => {
    if (!selectedImage) return;
    setLightboxLoading(true);
    const currentIndex = images.findIndex(img => img.id === selectedImage.id);
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex]);
  };

  const handlePrevious = () => {
    if (!selectedImage) return;
    setLightboxLoading(true);
    const currentIndex = images.findIndex(img => img.id === selectedImage.id);
    const previousIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[previousIndex]);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'ArrowLeft') {
      handlePrevious();
    } else if (event.key === 'Escape') {
      handleClose();
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart({
      startX: e.touches[0].clientX,
      startY: e.touches[0].clientY
    });
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!touchStart) return;

    const xDiff = touchStart.startX - e.touches[0].clientX;
    const yDiff = touchStart.startY - e.touches[0].clientY;

    // Only handle horizontal swipes (ignore vertical scrolling)
    if (Math.abs(xDiff) > Math.abs(yDiff) && Math.abs(xDiff) > swipeThreshold) {
      if (xDiff > 0) {
        handleNext();
      } else {
        handlePrevious();
      }
      setTouchStart(null);
    }
  };

  const handleTouchEnd = () => {
    setTouchStart(null);
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 64px)', // Subtract AppBar height
        overflow: 'auto',
        padding: { xs: 2, md: 4 },
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none'
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ textTransform: 'capitalize' }}>
        {category} Photography
      </Typography>
      
      <Grid container spacing={3}>
        {images.map((image) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={image.id}>
            <Card 
              sx={{ 
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.02)',
                  cursor: 'pointer'
                },
                userSelect: 'none'
              }}
              onClick={() => handleImageClick(image)}
            >
              <Box sx={{ position: 'relative' }}>
                {loadingStates[image.id] && (
                  <Skeleton 
                    variant="rectangular" 
                    height={300}
                    animation="wave"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      zIndex: 1
                    }}
                  />
                )}
                <CardMedia
                  component="img"
                  height="300"
                  image={image.url}
                  alt={image.title}
                  sx={{ 
                    objectFit: 'cover',
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    visibility: loadingStates[image.id] ? 'hidden' : 'visible',
                    pointerEvents: 'none',
                    WebkitTouchCallout: 'none'
                  }}
                  loading="lazy"
                  onLoad={() => handleImageLoad(image.id)}
                  draggable="false"
                />
              </Box>
              <CardContent>
                <Typography variant="subtitle1" component="h3" gutterBottom>
                  {image.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {image.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={selectedImage !== null}
        onClose={handleClose}
        maxWidth="lg"
        fullScreen={fullScreen}
        onKeyDown={handleKeyDown}
        onContextMenu={preventImageDownload}
        sx={{
          '& .MuiDialog-paper': {
            margin: 0,
            backgroundColor: 'background.paper',
            position: 'relative',
            userSelect: 'none'
          }
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            display: 'flex',
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
            zIndex: 1
          }}
        >
          <CloseIcon />
        </IconButton>
        
        <IconButton
          onClick={handlePrevious}
          sx={{
            position: 'absolute',
            left: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            zIndex: 1
          }}
        >
          <NavigateBeforeIcon />
        </IconButton>

        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white',
            zIndex: 1
          }}
        >
          <NavigateNextIcon />
        </IconButton>

        <DialogContent 
          sx={{ 
            p: 0, 
            backgroundColor: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '80vh',
            touchAction: 'pan-y pinch-zoom',
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {selectedImage && (
            <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
              {lightboxLoading && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1
                  }}
                >
                  <CircularProgress sx={{ color: 'white' }} />
                </Box>
              )}
              <img
                src={selectedImage.url}
                alt={selectedImage.title}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  pointerEvents: 'none',
                  WebkitTouchCallout: 'none'
                }}
                onLoad={handleLightboxImageLoad}
                draggable="false"
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  color: 'white',
                  p: 2
                }}
              >
                <Typography variant="h6">{selectedImage.title}</Typography>
                <Typography variant="body2">{selectedImage.description}</Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Gallery;
