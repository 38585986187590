import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Navigation from './components/Navigation';
import Home from './components/Home';
import Gallery from './components/Gallery';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/street" element={<Gallery category="street" />} />
          <Route path="/landscape" element={<Gallery category="landscape" />} />
          <Route path="/interior" element={<Gallery category="interior" />} />
          <Route path="/architecture" element={<Gallery category="architecture" />} />
          <Route path="/pets" element={<Gallery category="pets" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
